import Partners from "@/constants/partner/partner.constants"
import StoragePartnerConstants from "@/constants/partner/storage.constants"
import BrowserStorage from "@/services/storage/browserStorage";
import Configuration from "@/services/configuration";

const getConfigurations = function () {
    return {
        [Partners.TRADE_UP_GROUP]: {
            logo: () => import(/* webpackChunkName: "TradeAppOnePartner" */'@/assets/img/logos/trade-app-one.svg'),
            theme: () => import(/* webpackChunkName: "TradeAppOnePartner" */`@/assets/scss/themes/_default.theme.scss`),
            iconBy: () => import(/* webpackChunkName: "TradeAppOnePartner" */`@/assets/icons/icon-by-empty-white.svg`),
            icon: () => import(/* webpackChunkName: "TradeAppOnePartner" */`@/assets/icons/icon-login-white.svg`),
            analyticTag: Configuration?.partner?.tradeAppOne?.analyticTag || '',
            availableProducts: Configuration?.partner?.tradeAppOne?.availableProducts || {},
        },
        [Partners.PERNAMBUCANAS]: {
            logo: () => import(/* webpackChunkName: "PernambucanasPartner" */'@/assets/img/logos/pernambucanas.svg'),
            theme: () => import(/* webpackChunkName: "PernambucanasPartner" */`@/assets/scss/themes/_pernambucanas.theme.scss`),
            iconBy: () => import(/* webpackChunkName: "PernambucanasPartner" */`@/assets/icons/icon-buy-empty.svg`),
            icon: () => import(/* webpackChunkName: "PernambucanasPartner" */`@/assets/icons/icon-login.svg`),
            analyticTag: '',
            availableProducts: {},
        },
        [Partners.HPANDMCAFEE]: {
            logo: () => import(/* webpackChunkName: "HpMcafeePartner" */'@/assets/img/logos/hp-mcafee.svg'),
            theme: () => import(/* webpackChunkName: "HpMcafeePartner" */`@/assets/scss/themes/_hpmcafee.theme.scss`),
            iconBy: () => import(/* webpackChunkName: "HpMcafeePartner" */`@/assets/icons/icon-buy-empty.svg`),
            icon: () => import(/* webpackChunkName: "HpMcafeePartner" */`@/assets/icons/icon-login.svg`),
            analyticTag: Configuration?.partner?.mcafeeHp?.analyticTag || '',
            availableProducts: Configuration?.partner?.mcafeeHp?.availableProducts || {},
        },
        [Partners.HPMCAFEE]: {
            logo: () => import(/* webpackChunkName: "HpMcafeePartner" */'@/assets/img/logos/logo-mcafee-red.svg'),
            theme: () => import(/* webpackChunkName: "HpMcafeePartner" */`@/assets/scss/themes/_hpmcafee.theme.scss`),
            iconBy: () => import(/* webpackChunkName: "HpMcafeePartner" */`@/assets/icons/icon-buy-empty.svg`),
            icon: () => import(/* webpackChunkName: "HpMcafeePartner" */`@/assets/icons/icon-login.svg`),
            analyticTag: Configuration?.partner?.mcafeeHp?.analyticTag || '',
            availableProducts: Configuration?.partner?.mcafeeHp?.availableProducts || {},
        },
        [Partners.MCAFEE]: {
            logo: () => import(/* webpackChunkName: "McafeePartner" */'@/assets/img/logos/logo-mcafee-red.svg'),
            theme: () => import(/* webpackChunkName: "McafeePartner" */`@/assets/scss/themes/_mcafee.theme.scss`),
            iconBy: () => import(/* webpackChunkName: "McafeePartner" */`@/assets/icons/icon-buy-empty.svg`),
            icon: () => import(/* webpackChunkName: "McafeePartner" */`@/assets/icons/icon-login.svg`),
            analyticTag: Configuration?.partner?.mcafee?.analyticTag || '',
            availableProducts: Configuration?.partner?.mcafee?.availableProducts || {},
        },
        [Partners.RIGSTAR]: {
            logo: () => import(/* webpackChunkName: "McafeePartner" */'@/assets/img/logos/logo-mcafee-red.svg'),
            theme: () => import(/* webpackChunkName: "McafeePartner" */`@/assets/scss/themes/_mcafee.theme.scss`),
            iconBy: () => import(/* webpackChunkName: "McafeePartner" */`@/assets/icons/icon-buy-empty.svg`),
            icon: () => import(/* webpackChunkName: "McafeePartner" */`@/assets/icons/icon-login.svg`),
            analyticTag: Configuration?.partner?.mcafeeRigstar?.analyticTag || '',
            availableProducts: Configuration?.partner?.mcafeeRigstar?.availableProducts || {},
        },
        [Partners.CARREFOUR]: {
            logo: () => import(/* webpackChunkName: "CarrefourPartner" */'@/assets/img/logos/carrefour.svg'),
            theme: () => import(/* webpackChunkName: "CarrefourPartner" */`@/assets/scss/themes/_carrefour.theme.scss`),
            iconBy: () => import(/* webpackChunkName: "CarrefourPartner" */`@/assets/icons/icon-by-empty-red.svg`),
            icon: () => import(/* webpackChunkName: "CarrefourPartner" */`@/assets/icons/icon-login-red.svg`),
            analyticTag: '',
            availableProducts: {},
        },
        [Partners.MASTERCARD]: {
            logo:   () => import(/* webpackChunkName: "MastercardPartner" */ '@/assets/mastercard-partner/img/svg/logo.svg'),
            theme:  () => import(/* webpackChunkName: "MastercardPartner" */ '@/assets/mastercard-partner/design/theme.scss'),
            iconBy: () => import(/* webpackChunkName: "MastercardPartner" */ '@/assets/icons/icon-buy-empty.svg'),
            icon:   () => import(/* webpackChunkName: "MastercardPartner" */ '@/assets/icons/icon-login-red.svg'),
            analyticTag: Configuration.partner.mastercard.analyticTag,
            availableProducts: Configuration?.partner?.mastercard?.availableProducts || {},
            banner: {
                image: () => import(/* webpackChunkName: "MastercardPartner" */ '@/assets/mastercard-partner/img/png/banner.png'),
                alt: 'Três pessoas sorrindo e usando smartphones. Promoção do programa Mastercard Surpreenda com 30 dias grátis do McAfee Total Protection e 50% de desconto.'
            },
        },
    }
}

const getDefaultPartner = function () {
    return Partners.TRADE_UP_GROUP
}

const getPartnerConfiguration = function (partner) {
    return getConfigurations()[partner?.toLowerCase()] || null
}

const setActivePartner = function (partner) {
    BrowserStorage.set(StoragePartnerConstants.ACTIVE_PARTNER, partner)
}

const getActivePartner = function () {
    return BrowserStorage.get(StoragePartnerConstants.ACTIVE_PARTNER)
}

export default {
    getDefaultPartner,
    getPartnerConfiguration,
    setActivePartner,
    getActivePartner,
}
